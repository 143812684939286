import React from 'react'
import styled from 'styled-components';
import CardsImg from '../images/cards.png';

const Img = styled.img`
    width: 15em;
    display: block;
    margin: 1em auto;
`

export default function Cards() {
    return <Img src={CardsImg} alt="אמצעי תשלום אפשריים" />;
}
