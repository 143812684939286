import React, { useEffect, useState } from 'react'

import styled from 'styled-components';
const Iframe = styled.iframe`
  width:100%;
  height: 48em;
  min-height: 97vh;
  border: none;
`;

export default function PelecardCheckout(props) {
    const { transactionId } = props.match.params;


    const [iframeUrl, setIframeUrl] = useState();
    useEffect(() => {
        const iframe_URL = `https://gateway20.pelecard.biz/PaymentGW?transactionId=${transactionId}`;
        console.log('iframe_URL', iframe_URL);
        setIframeUrl(iframe_URL);
    }, [transactionId])


    const [iframeReady, setIframeReady] = useState(false);

    useEffect(() => {
        if (iframeUrl) setTimeout(() => {
            setIframeReady(true);
        }, 2300 + 1000);
    }, [iframeUrl])

    return (
        <div>
            {!iframeReady && <div className="loading-wrap for-one-second">
                <div className="background"></div>
                <div className="loading">
                    <div className="spinner"></div>
                    <div className="text">טוען...</div>
                </div>
            </div>}
            {/* <Iframe src={iframeUrl} title="payment"></Iframe> */}
            <Iframe src={iframeUrl} title="payment" sandbox="allow-top-navigation-by-user-activation allow-top-navigation allow-scripts allow-same-origin allow-modals allow-forms"></Iframe>
            {/* <Iframe src={iframeUrl} title="payment" sandbox="allow-top-navigation allow-scripts allow-forms	"></Iframe> */}
        </div>
    )
}
