/* eslint-disable no-unused-vars */
import React, { } from 'react';
import { Route, Switch } from 'react-router-dom';
import Donate from './routes/Donate';
import Thanks from './routes/Thanks';
import Error from './routes/Error';
import PelecardCheckout from './routes/PelecardCheckout';
import NotWorking from './routes/NotWorking';

function App() {

  return (
    <div className="App">
      <NotWorking />
      {/* <Switch>
        <Route path="/error" component={Error} />
        <Route path="/thanks" component={Thanks} />
        <Route path="/pay/:transactionId" component={PelecardCheckout} />
        <Route exact path="/" component={Donate} />
      </Switch> */}
    </div>
  );
}

export default App;
