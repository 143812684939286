import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form';
import { API_URL } from '../config';
import axios from 'axios';
import Header from '../components/Header';
import Cards from '../components/Cards';
import { GrowingInput } from '../components/GrowingInput';
import getQueryVariable from '../helpers/getQueryVariable';
import urlDecode from '../helpers/urlDecode';
import Loading from '../components/Loading';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';

function financial(x) {
  return Number.parseFloat(x).toFixed(2);
}


const schema = yup.object().shape({
  total: yup.number().typeError('הסכום נמוך מדי לחיוב').min(0.10, 'הסכום נמוך מדי לחיוב'),
  isRequestingInvoice: yup.boolean(),
  name: yup.string().when('isRequestingInvoice', (isRequestingInvoice, schema) => {
    return isRequestingInvoice ? schema.required('נא למלא').min(2, 'קצר מדי') : schema;
  }),
  email: yup.string().when('isRequestingInvoice', (isRequestingInvoice, schema) => {
    return isRequestingInvoice ? schema.required('נא למלא').email('נא להכניס מייל תקין') : schema;
  })
});

export default function Donate() {

  const { register, handleSubmit, setValue, watch, control, formState } = useForm({ mode: 'onTouched', resolver: yupResolver(schema) });
  const errors = useMemo(() => formState.errors, [formState]);

  const watchIsRequestingInvoice = watch("isRequestingInvoice", false); // you can supply default value as second argument


  const [item, setItem] = useState();

  useEffect(() => {
    const item = getQueryVariable('item') ? urlDecode(getQueryVariable('item')) : '';
    setItem(item);
    if (Number.parseFloat(getQueryVariable('amount'))) setValue('total', financial(getQueryVariable('amount')));
    if (getQueryVariable('customer_name') || getQueryVariable('customer_email')) {
      setValue('isRequestingInvoice', true);
      setValue('name', urlDecode(getQueryVariable('customer_name')) || '');
      setValue('email', decodeURIComponent(getQueryVariable('customer_email')) || '');
      setValue('return_url', urlDecode(getQueryVariable('return')) || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function get_transaction_id(data) {
    const path = `${API_URL}/payment-method/${data.payment_method}/checkout`;
    console.log('path', path);


    const sentData = {
      total: parseFloat(data.total) * 100, // in decimals
      customer_name: data.name,
      customer_email: data.email,
      isRequestingInvoice: data.isRequestingInvoice,
      return_url: data.return_url,
      item: item || ''
    };
    console.log('sent data:', sentData);
    return axios.post(path, sentData).then((res) => {
      console.log('res.data', res.data);
      if (res.data?.payment_url) {
        setIsSuccessfullySubmitted(true);
        if (res.data?.force_redirect === true) {
          window.location.href = res.data.payment_url;
        } else {
          if (res.data.transaction_id) return res.data.transaction_id;
        }
      }
      // return res.data;
    }).catch(err => console.error(err));
  }

  const [transactionId, setTransactionId] = useState();

  const onSubmit = async (data) => {
    console.log(data);
    const transactionId = await get_transaction_id(data);
    console.log('transactionId', transactionId);
    setTransactionId(transactionId);
  };

  function handleAmountChange(value) {
    value = financial(parseInt(value.replace(/[^\d]/, '')) / 100);
    console.log('value', value);
    setValue('total', value);
    return value;
  };

  let history = useHistory();

  useEffect(() => {
    if (transactionId)
      history.push(`/pay/${transactionId}`);
  }, [history, transactionId])

  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);

  return (
    <div>

      <Header></Header>

      <div className="wrap">
        <div className="bordered">
          <form onSubmit={handleSubmit(onSubmit)} noValidate>

            <div className="whats-happening">
              <div className="send-to">שליחת תרומה אל</div>
              <div className="reciever">אלרון מהפונטון</div>
              {item && <div className="for">עבור {item}</div>}
            </div>

            <div className="amount-wrap">
              <GrowingInput
                // render={(field) =><GrowingInput />}
                type="number"
                name="total"
                control={control}
                placeholder="0.00"
                handleAmountChange={handleAmountChange}
              />
              <span className="currency">₪</span>
            </div>
            <div className="error amount-message">{errors.total && errors.total.message}</div>
            {/* <select {...register("payment_method")}>
              <option value="pelecard">תשלום באשראי באמצעות פלאקארד</option>
              <option value="paypal">תשלום באמצעות PayPal</option>
            </select> */}
            {!watchIsRequestingInvoice ? <>
              <label htmlFor="isRequestingInvoice">
                <div className="kabala want-kabala">
                  <input type="checkbox" className="hidden" {...register("isRequestingInvoice")} id="isRequestingInvoice" />
                  <div className="checkbox"></div>
                  אשמח לקבל חשבונית מס קבלה
                </div>
              </label>
            </> :
              <div className="kabala">
                <div className="form-group">
                  <label htmlFor="name">על שם מי להוציא חשבונית מס קבלה?</label>
                  <input type="text" autoFocus id="name" {...register("name")} placeholder="השם המלא שלך או של העסק..." />
                  <div className="error">{errors.name && errors.name.message}</div>
                </div>
                <div className="form-group">
                  <label htmlFor="email">לאיזה מייל לשלוח אותה?</label>
                  <input type="email" id="email" {...register("email")} placeholder="המייל.שלך@כאן" />
                  <div className="error">{errors.email && errors.email.message}</div>
                </div>
              </div>
            }
            {formState.isSubmitting ?
              <>
                <Loading>טוען...</Loading>
              </> :
              formState.isSubmitSuccessful && isSuccessfullySubmitted ?
                <>
                  <Loading>מעביר אותך לדף התשלום...

                    {transactionId}
                  </Loading>
                </> : <>
                  {/* <Loading>טוען...</Loading> */}

                </>
            }
            {!formState.isSubmitting && formState.isSubmitSuccessful && !isSuccessfullySubmitted && <div className="error centered">הייתה בעיה. נסו שוב.</div>}
            <input type="hidden" id="return" {...register("return")} placeholder="" />
            <button className="fullwidth mt-3" onClick={() => setValue('payment_method', 'pelecard')}>תרומה באמצעות <span className="nowrap">כרטיס אשראי</span></button>
            <button className="fullwidth hollow mt-3" onClick={() => setValue('payment_method', 'paypal')}>תרומה באמצעות <strong>PayPal</strong></button>
          </form>
        </div>
      </div>

      <Cards />

      {/* <div dir="ltr">
        <pre>{simpleStringify(formState)}</pre>
      </div> */}

    </div>
  )
}

// function simpleStringify(object) {
//   var simpleObject = {};
//   for (var prop in object) {
//     if (!object.hasOwnProperty(prop)) {
//       continue;
//     }
//     if (typeof (object[prop]) == 'object') {
//       continue;
//     }
//     if (typeof (object[prop]) == 'function') {
//       continue;
//     }
//     simpleObject[prop] = object[prop];
//   }
//   return JSON.stringify(simpleObject); // returns cleaned up JSON
// };