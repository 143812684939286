import React from "react";

export default function NotWorking() {
  return (
    <div className="middle">
      <div class="bordered centered  more-padding">
        <h1>סורי 😅</h1>
        <p>אתר התרומות סגור כרגע</p>
        <p>אבל אני מעריך מאוד את הכוונה שלך! 💙</p>
        <a
          href="https://hafonton.co.il/shop/%D7%A4%D7%95%D7%A0%D7%98%D7%99%D7%9D/"
          className="button mt-3"
        >
          {/* אבל אפשר לקנות פונטים במקום */}
          שנטייל במקום אחר?
        </a>
      </div>
    </div>
  );
}
